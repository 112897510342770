import React from 'react';
import { PageHeader } from '../components/PageHeader';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';

const NotFoundPage = (props) => {
  const { data, location } = props;
  const { wordpressWpSettings } = data;
  const { title } = wordpressWpSettings;
  return (
    <div>
      <SEO title={`404 | ${title}`} />
      <PageHeader headerTitle="404 Not Found" headerSubTitle={`The page "${location.pathname}" cannot be found.`} compact={false} location={location} />
    </div>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
    }
  }
`
